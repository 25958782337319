<template>
  <div class="container">
    <van-button class="back_style" type="primary" @click="goNext">
    {{$t('common.back')}}</van-button>
  </div>
</template>
<script>
  export default {
    props: {
      type: String,
    },
    data() {
      return {}
    },
    methods: {
      goNext() {
        if(this.type == 'login'){
          this.$router.push({
              name: "login",
            });
        }else if(this.type == 'index'){
          this.$router.push({
            name: "index",
          });
        }else{
          this.$router.go(-1)
        }
      },
    }
  }

</script>
<style lang="less" scoped>
  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .back_style {
      width: 100%;
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #E5E5E5;
      font-size: 32px;
      color: #333333;
      margin-bottom:40px;
    }
  }

</style>
