import request from '@/utils/request'
import { getUuid } from "@/utils/auth"
import queryString from 'query-string';

/**
 * 获取会议信息
 */
export function fetchConference(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.id}`,
    method: 'get'
  })
}


/**
 * 
 */
export function fetchConferenceSnowflake() {
  return request({
    url: `/ybmeet-sequence/api/snowflake/get/ybmeet`,
    method: 'get'
  })
}


/**
 * 获取导出信息
 */
export function fetchRoomExport(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.rtcRoomNo}/detail/export`,
    method: 'get'
  })
}


/**
 * 导出信息
 */
export function exportRoomInfo(data) {
  return request({
    url: `/ybmeet-conference/conference/download/detail`,
    method: 'get',
    params: data,
    isBlobType: true,
    responseType: "blob"
  })
}


/**
 * 获取agenda info
 */
export function fetchAgendaInfo(data) {
  return request({
    url: `/ybmeet-conference/conference/agenda/info`,
    method: 'get',
    params: data
  })
}



/**
 * 个人信息
 */
export function fetchUserInfo() {
  return request({
    url: `/ybmeet-conference/conference/getUserInfo`,
    method: 'get'
  })
}


/**
 * 是否存在企业账号
 */
export function exitEnterprise(data) {
  return request({
    url: `/middle-auth/enterprise/exit/${data.userName}`,
    method: 'get'
  })
}

/**
 * 是否存在个人账号
 */
export function exitPermituser(data) {
  return request({
    url: `/middle-auth/permituser/exit/${data.userName}`,
    method: 'get'
  })
}

/**
 * 登录
 */
export function oauthLogin(data) {
  return request({
    url: '/middle-auth/login/oauth2/token',
    method: 'post',
    data
  })
}


/**
 * reconnection
 */
export function fetchReconnectionInfo(data) {
  return request({
    url: '/ybmeet-conference/conference/reconnection',
    method: 'post',
    data
  })
}

/**
 * 创建会议
 */
export function createConference(data) {
  return request({
    url: `/ybmeet-conference/conference/create`,
    method: 'post',
    data
  })
}

/**
 * 加入会议
 */
export function joinConference(data) {
  return request({
    url: `/ybmeet-conference/conference/join`,
    method: 'post',
    data
  })
}


/**
 * 强制结束会议
 */
export function endConference(data) {
  return request({
    url: `/ybmeet-conference/conference/force/end/${data.id}`,
    method: 'post'
  })
}

/**
 * 会控
 */
export function fetchMeetControl(data, meetConfig = {}) {
  const { metaData, toPeerId, command } = data
  const { roomId, peerId } = meetConfig

  const formData = {
    room: roomId, // 房间id
    seq: new Date().getTime(),
    command,
    metadata: metaData || {},
    to: {
      peerid: toPeerId || '',
    },
    from: {
      peerid: peerId,
      deviceid: getUuid()
    }
  }

  formData.metadata = JSON.stringify(formData.metadata);

  return request({
    url: '/ybmeet-conference/conference/control',
    method: 'post',
    data: formData
  })
}


/**
 * 用户注册
 */
export function permituserRegist(data) {
  return request({
    url: `/middle-auth/permituser/regist`,
    method: 'post',
    data
  })
}

/**
 * 发送验证码
 */
export function sendCode(data) {
  return request({
    url: `/middle-auth/sms/send/code`,
    method: 'post',
    data
  })
}
// 校验验证码
export function regCode(data) {
  return request({
    url: `/middle-auth/sms/send/code/${data.phone}/${data.code}`,
    method: 'get',
    data
  })
}


/**
 * 更新用户信息
 */
export function updateUserInfo(data) {
  return request({
    url: `/middle-auth/user/info/update`,
    method: 'post',
    data
  })
}

/**
 * 修改密码
 */
export function modifyPassword(data, type) {
  return request({
    url: `/middle-auth/${type === 1 ? 'enterprise' :'permituser'}/modifypassword`,
    method: 'post',
    data
  })
}

/**
 * 忘记密码
 */
export function forgetPassword(data) {
  return request({
    url: `/middle-auth/permituser/forgetpassword`,
    method: 'post',
    data
  })
}


/**
 * 今日列表
 */
export function fetchTodayList(data) {
  return request({
    url: `/ybmeet-conference/conference/today/list`,
    method: 'post',
    data
  })
}

/**
 * 预定列表
 */
export function fetchOrderList(data) {
  return request({
    url: `/ybmeet-conference/conference/order/list`,
    method: 'post',
    data
  })
}

/**
 * 历史详情
 */
export function fetchHistoryDetail(data) {
  return request({
    url: `/ybmeet-conference/conference/history/detail`,
    method: 'post',
    data
  })
}




/**
 * 更新会议
 */
export function updateConference(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.id}`,
    method: 'put',
    data
  })
}

/**
 * 更新个人会议
 */
export function updatePersonConference(conferenceNo, data) {
  return request({
    url: `/ybmeet-conference/conference/person/${conferenceNo}`,
    method: 'put',
    data
  })
}


/**
 * 删除会议
 */
export function delConference(data) {
  return request({
    url: `/ybmeet-conference/conference/${data.id}`,
    method: 'delete'
  })
}

/**
 * 删除房间
 */
export function delMeetRoom(data) {
  return request({
    url: `/ybmeet-conference/conference/room/${data.id}`,
    method: 'delete'
  })
}


/*********************************/
/**
 * 获取小程序urllink
 */
 export function fetchMiniProgramUrlLink(data = {}) {
  const queryStr = queryString.stringify(data)
  return request({
    url: `/ybmeet-conference/conference/urlLink?path=pages/index/index&query=${queryStr}`,
    method: 'get'
  })
}

//敏感词检测
export function wordsFilter(data) {
  return request({
    url: `/swgw/sensitive-words/sensitive/words/filter`,
    method: 'post',
    data
  })
}