<template>
  <div class="container">
    <div class="content">
      <div class="login_top">
        <div class='login_top_left'>{{$t('login.setPwd')}}</div>
      </div>
      <div class="login_filed">
        <van-cell-group>
          <van-field v-model="loginForm.firstpwd" :type="firstType" minlength='8' clearable maxlength='16'
          :placeholder="$t('login.pwsTips')">
            <my-icon fontSize="32" slot='right-icon' :iconName="iconfirst" @click="showPwd('first')"></my-icon>
          </van-field>
          <van-field v-model="loginForm.secondPwd" minlength='8' clearable maxlength='16' :type="secondType"
            :placeholder="$t('login.inputSurePws')">
            <my-icon fontSize="32" slot='right-icon' :iconName="iconsecond" @click="showPwd('second')"></my-icon>
          </van-field>
        </van-cell-group>
      </div>
      <van-button class="login_bn" :disabled="isDisableLoginBtn()" type="primary" @click="handleFinish">
        {{$t('login.save')}}</van-button>
      <Back :type='goType'></Back>
    </div>
  </div>
</template>
<script>
  import Back from "@/views/login/back.vue";
  import sha256 from "crypto-js/sha256";
  import Base64 from "crypto-js/enc-base64";
  import CryptoJS from "crypto-js";
  import {
    forgetPassword
  } from '@/api/meet'
  import {
    checkpwd,
  } from '../../utils/check'
  export default {
    components: {
      Back
    },
    data() {
      return {
        goType:'setPwd',
        loginForm: {
          firstpwd: '',
          secondPwd: '',
        },
        codeDisabled: false,
        firstType: 'password',
        secondType: 'password',
        iconfirst: 'icon-mima_bukedu_48_hei',
        iconsecond: 'icon-mima_bukedu_48_hei',
      }
    },
    methods: {
      showPwd(type) {
        let bukedu = 'icon-mima_bukedu_48_hei',
          kedu = 'icon-mima_kedu_48_hei';
        if (type == 'first') {
          this.firstType = this.firstType == 'test' ? 'password' : 'test'
          this.iconfirst = this.iconfirst == bukedu ? kedu : bukedu
        } else {
          this.secondType = this.secondType == 'test' ? 'password' : 'test'
          this.iconsecond = this.iconsecond == bukedu ? kedu : bukedu
        }
      },
      isDisableLoginBtn() {
        return !(this.loginForm.firstpwd && this.loginForm.secondPwd)
      },
      async handleFinish() {
        let that = this;
        if(!checkpwd(this.loginForm.firstpwd) || !checkpwd(this.loginForm.secondPwd)){
          this.$toast('密码由8-16位大小写字母、数字以及字符组成')
          return 
        }
        if (that.loginForm.firstpwd.length < 8 && that.loginForm.secondPwd.length < 8) {
          this.$toast(that.$t('reg.lengthPwd'))
          return
        }
        if (that.loginForm.firstpwd !== that.loginForm.secondPwd) {
          this.$toast(that.$t('reg.somePwd'))
          return
        }
        let data = {};
        data.password = Base64.stringify(
          CryptoJS.enc.Utf8.parse(sha256(that.loginForm.firstpwd).toString())
        );
        data.username = this.$route.query.username;
        data.checkCode = this.$route.query.code
        try {
          const resData = await forgetPassword(data)
          this.$router.push({
            path: 'finish',
          })
        } catch (error) {}
      },
    }
  }

</script>
<style lang="less" scoped>
  /* 登录部分输入框样式 */
  /deep/ .van-cell {
    padding: 10px 2px;
    margin-top: 18px;
    border-bottom: 0.1px solid #E5E5E5;

    &::after {
      right: 2px;
      left: 2px;
      border: none;
    }

    &:hover {
      border-bottom: 0.1px solid #1AB370;
    }
  }

  /deep/ .van-hairline--top-bottom::after {
    border: none;
  }

  .container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .content {
      padding: 80px 64px;
      box-sizing: border-box;

      .login_top {
        display: flex;
        justify-content: space-between;
        line-height: 64px;

        &_left {
          font-size: 48px;
          color: #333333;
        }
      }

      .login_filed {
        margin-top: 60px;

        .public-code() {

          width: 198px;
          height: 64px;
          border-radius: 4px;
          font-size: 24px;
        }

        .btn_style {
          background: rgba(255, 255, 255);
          border: 1px solid #FF8200;
          color: #FF8200;
          .public-code();


          &:focus {
            background: rgba(255, 130, 0, 0.1);
          }

          &:hover {
            background: rgba(255, 130, 0, 0.2);
          }
        }

        .disabled_style {
          border: none;
          background: #FFDFA3;
          color: #FFFFFF;
          .public-code();

        }
      }

      .login_bn {
        width: 622px;
        height: 88px;
        background: #1AB370;
        border-radius: 10px;
        border: none;
        margin: 80px 0 40px 0;
        font-size: 32px;
      }
    }
  }

</style>
